
// import $ from 'jquery';
import mobileNav from './components/mobileNav';
import accessibleMenu from './components/accesibleMenu';
import scrolled from './components/scrolled';
import counter from './components/counter';
import accordeons from './components/accordeons';
import syntheseProjets from './components/syntheseProjets';

mobileNav();
accessibleMenu();
scrolled();
counter('count-box-1');
counter('count-box-2');
counter('count-box-3');
counter('count-box-4');
counter('count-box-5');
syntheseProjets();

document.addEventListener('DOMContentLoaded', function() {
  // remove type="" attribute in <style></style> and <script></script>
  const scriptJsArr = Array.from(document.querySelectorAll('script'));
  const styleCssArr = Array.from(document.querySelectorAll('style'));

  scriptJsArr.forEach(function(scriptJs) {
    scriptJs.removeAttribute('type');
  });

  styleCssArr.forEach(function(styleCss) {
    styleCss.removeAttribute('type');
  });

  // Add class .no-sidebar for full-width content
  const asideArea = document.querySelector('#sidebar');
  const contentArea = document.querySelector('#content');

  // console.log(asideArea);
  if (!asideArea) {
    // console.log('No Sidebar!');
    if (contentArea !== null) {
      contentArea.classList.add('no-sidebar');
    }
  }

  if (document.querySelector('.elementor-widget-accordion')) {
    accordeons();
  }
});
