import $ from 'jquery';

function numStr(a, b) {
  a = '' + a;
  b = b || ' ';
  var c = '',
      d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
  }
  for (var i = a.length-1; i >= 0; i--) {
    c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
    d++;
  }
  return c;
}

export default function counter(count) {
  //Fact Counter + Text Count
  // if ($('.count-box').length) {
  // $('.count-box').appear(
  //   function() {
  var $t = $('.' + count + ''),
    n = $t.find('.count-text').attr('data-stop'),
    r = parseInt($t.find('.count-text').attr('data-speed'), 10);

  if (!$t.hasClass('counted')) {
    $t.addClass('counted');
    $({
      countNum: $t.find('.count-text').text()
    }).animate(
      {
        countNum: n
      },
      {
        duration: r,
        easing: 'linear',
        step: function() {
          $t.find('.count-text').text(numStr(Math.floor(this.countNum)));
        },
        complete: function() {
          $t.find('.count-text').text(numStr(this.countNum));
        }
      }
    );
  }
  //   },
  //   { accY: 0 }
  // );
  // }
}
