import $ from 'jquery';
import Isotope from 'isotope-layout';

export default function syntheseProjets() {

//  var $grid = $('#tableau-synthese .tbody');
//  $grid.isotope({

  var grid = document.querySelector('#tableau-synthese .tbody');
  if (! grid) {
    return;
  }
  var iso = new Isotope(grid, {
    itemSelector: '.item',
    layoutMode: 'vertical'
  });

  $('.filter-btn-group').on('click', 'button', function() {
    var filterValue = $(this).attr('data-filter');
    iso.arrange({
      filter: filterValue
    });
    $(this).parent().find('.active').removeClass('active');
    $(this).addClass('active');
  });

  iso.arrange({
    filter: '.tous'
  });
  $('#filter-btn-0').parent().find('.active').removeClass('active');
  $('#filter-btn-0').addClass('active');
}

