export default function accordeons() {
  const tabTitleArray = Array.from(document.querySelectorAll('.elementor-tab-title'));
  const tabContentArray = Array.from(document.querySelectorAll('.elementor-tab-content'));
  const activeArray = Array.from(document.querySelectorAll('.elementor-active'));

  activeArray.forEach(function(active) {
    active.classList.remove('elementor-active');
  });
  tabContentArray.forEach(function(tabContent) {
    tabContent.style.display = 'none';
  });
}
