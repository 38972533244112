export default function scrolled() {
  window.addEventListener('scroll', function(e) {
    const body = document.querySelector('body');

    if (window.scrollY > 0) {
      body.classList.add('scrolled');
    } else if (window.scrollY == 0) {
      body.classList.remove('scrolled');
    }
  });
}
